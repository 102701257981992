import { map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Common, FionaResponse } from "@core";
import { environment } from "@env/environment";
import { HttpClient } from "@angular/common/http";
import { ICellRendererParams } from 'ag-grid-community';

export interface DataFilter {
    fechaInicio: string;
    fechaFin: string;
}

@Injectable({
    providedIn: "root"
})
export class ParkingService {
    private readonly ENDPOINT: string = "parqueadero";

    constructor(
        private http: HttpClient,
        private common: Common,
    ) { }

    public getColumnDefsAgg(_cellRendererParams: any) {
        return [
            {
                field: "",
                headerName: "",
                checkboxSelection: true
            },
            {
                field: "ticketCobro.numeroTicket",
                headerName: "Ticket",
            },
            {
                field: "placa",
                headerName: "Placa",
            },
            {
                field: "ingreso",
                headerName: "Fecha Ingreso",
                sort: "desc",
            },
            {
                field: "salida",
                headerName: "Fecha Salida",
            },
            {
                field: "moduloEntrada.modulo",
                headerName: "Módulo Entrada",
            },
            {
                field: "entradaNotificada",
                headerName: "Entrada",
                cellRenderer: "badgeIntNotificationInComponent",
            },
            {
                field: "moduloSalida.modulo",
                headerName: "Módulo Salida",
            },
            {
                field: "salidaNotificada",
                headerName: "Salida",
                cellRenderer: "badgeIntNotificationOutComponent",
            },
            {
                field: "cantidad",
                headerName: "Cantidad",
            },
            {
                field: "motoBase",
                headerName: "Valor Base",
                valueFormatter: this.common.currencyFormatter,
                cellClass: "currencyFormat"
            },
            {
                field: "motoImpuesto",
                headerName: "Valor Impuesto",
                valueFormatter: this.common.currencyFormatter,
                cellClass: "currencyFormat"
            },
            {
                field: "motoTotal",
                headerName: "Valor Total",
                valueFormatter: this.common.currencyFormatter,
                cellClass: "currencyFormat"
            },
            {
                field: "tipo",
                headerName: "Tipo de Pago",
                cellRenderer: "iconTipoPagoComponent",
            },
            {
                field: "estado",
                headerName: "Estado Ticket",
                cellRenderer: "badgeEstadoTicketComponent",
                cellRendererParams: _cellRendererParams,
            }
        ]
    }

    public all(data: DataFilter) {
        return this.http.post<FionaResponse<Array<any>>>(`${environment.urlApiFiona}/${this.ENDPOINT}`, data);
    }

    /**
     * reliquidate
     */
    public reliquidate(id: number) {
        return this.http.put<FionaResponse<string>>(`${environment.urlApiFiona}/${this.ENDPOINT}/${id}/tarificar`, {});
    }

    getExitForce(idTicketCobro: number) {
        return this.http.get<FionaResponse<any>>(`${environment.urlApiFiona}/${this.ENDPOINT}/${idTicketCobro}/forzarSalida`);
    }
}