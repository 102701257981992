
import { Injectable } from "@angular/core";
import { Common, FionaResponse } from "@core";
import { environment } from "@env/environment";
import { VehicleInventory } from "@core/models";
import { HttpClient, HttpHeaders } from "@angular/common/http";

/**
 * Servicio para inventario de vehículos.
 */
@Injectable({
    providedIn: "root"
})
export class VehicleInventoryService {
    private readonly ENDPOINT: string = "parqueadero";

    constructor(
        private http: HttpClient,
    ) { }

    public getColumnDefsAgg() {
        return [
            {
                flex: 1,
                field: "id",
                headerName: "",
                checkboxSelection: true,
            },
            {
                flex: 2,
                field: "placa",
                headerName: "Placa",
            },
            {
                flex: 2,
                sort: 'desc',
                field: "date",
                headerName: "Fecha Entrada",
            },
            {
                flex: 2,
                field: "moduloEntrada",
                headerName: "Módulo",
            },
            {
                flex: 2,
                field: "numeroTicket",
                headerName: "N. Ticket",
            },
        ];
    }

    public all() {
        return this.http.get<FionaResponse<Array<VehicleInventory>>>(`${environment.urlApiFiona}${this.ENDPOINT}/inventarioVehiculos`);
    }

    public force(data: { ticketCobroId: number, observacion: string }) {
        return this.http.put<FionaResponse<string>>(`${environment.urlApiFiona}${this.ENDPOINT}/${data.ticketCobroId}/forzarSalida`, data);
    }

    public exit(headers: { codigoEstacionamiento: string, codigoModulo: string, fechaHora: string }, data: { salida: string, tags: Array<string> }) {
        let header: HttpHeaders =  new HttpHeaders()
        .set("codigoEstacionamiento", headers.codigoEstacionamiento)
        .set("codigoModulo", headers.codigoModulo)
        .set("fechaHora", headers.fechaHora);
        return this.http.post<FionaResponse<string>>(`${environment.urlApiFionaParking}parking/registrarSalida`, data, { headers: header });
    }
}
