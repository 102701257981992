import { Observable } from "rxjs";
import { FionaResponse } from "@core";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { ModulePointAttent } from "@core/models";
import { HttpClient } from "@angular/common/http";

@Injectable({
    providedIn: "root"
})
export class ModulePointAttentService {
    private readonly ENDPOINT: string = "moduloPtoAtencion";

    constructor(
        private http: HttpClient,
    ) { }

    public all(): Observable<FionaResponse<Array<ModulePointAttent>>> {
        return this.http.get<FionaResponse<Array<ModulePointAttent>>>(`${environment.urlApiFiona}${this.ENDPOINT}`);
    }

    public add(request: ModulePointAttent): Observable<FionaResponse<Array<string>>> {
        return this.http.post<FionaResponse<Array<string>>>(`${environment.urlApiFiona}${this.ENDPOINT}`, request);
    }

    public update(request: ModulePointAttent): Observable<FionaResponse<Array<string>>> {
        return this.http.put<FionaResponse<Array<string>>>(`${environment.urlApiFiona}${this.ENDPOINT}/${request.id}`, request);
    }

    public delete(id: number): Observable<FionaResponse<Array<string>>> {
        return this.http.delete<FionaResponse<Array<string>>>(`${environment.urlApiFiona}${this.ENDPOINT}`);
    }
}