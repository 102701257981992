import { tap } from "rxjs/operators";
import { LocalStorage } from "@core";
import { Observable, of } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { CredentialsService } from "./cred.service";
import { environment } from "src/environments/environment";


export interface LoginContext {
    username: string;
    password: string;
    remember?: boolean;
}

export interface AuthModel {
    usrCodigo: string;
    clave: string;
    codEmpresa: string;
}

/**
 * Provides a base for authentication workflow.
 * The login/logout methods should be replaced with proper implementation.
 */
@Injectable({
    providedIn: "root"
})
export class AuthService {

    constructor(
        private http: HttpClient,
        private localStorage: LocalStorage,
        private credentialsService: CredentialsService) { }

    /**
     * Authenticates the user.
     * @param context The login parameters.
     * @return The user credentials.
     */
    login(context: LoginContext): Observable<any> {
        // Replace by proper authentication call
        const data: AuthModel = {
            usrCodigo: context.username,
            clave: context.password,
            codEmpresa: ""
        };


        //TODO: Cambiar FIONA por una variable global.
        return this.http.post<any>(environment.urlApiAdmin + "Autenticacion" + "/Modulo/" + "FIONA", data)
            .pipe(
                tap(response => {
                    this.credentialsService.setCredentials(response, true);
                    return of(response)
                })
            )
    }

    public cerrarSesion() {
        return this.http.put<any>(environment.urlApiAdmin + "usuario" + "/CerrarSesion", null)
            .pipe(
                tap(response => {
                    this.localStorage.limpiarLocalStorage();
                    return response;
                })
            );
    }

    public obtenerPerfil() {
        return this.http.get<any>(environment.urlApiAdmin + "usuario" + "/Perfil")

    }

    public actualizarPerfil(usuarioModificado: any) {
        return this.http.put<any>(environment.urlApiAdmin + "usuario" + "/Perfil", usuarioModificado)

    }

    public obtenerMisEmpresas() {
        return this.http.get<any>(environment.urlApiAdmin + "usuario" + "/Empresas")

    }

    public obtenerMenus() {
        return this.http.get<any>(environment.urlApiAdmin + "usuario" + "/Menus")
            .pipe(
                tap((response) => {
                    this.localStorage.guardarMenus(response);
                    return response;
                })
            )
    }

    public obtenerVistas() {
        return this.http.get<any>(environment.urlApiAdmin + "usuario" + "/Vistas")
            .pipe(
                tap((response) => {
                    this.localStorage.guardarVistas(response);
                    return response;
                })
            )
    }

    public obtenerReportes() {
        return this.http.get<any>(environment.urlApiAdmin + "usuario" + "/MisReportes")

    }

    public cambiarEntornoEmpresa(empCodigo: string, modCodigo: string) {
        return this.http.put<any>(environment.urlApiAdmin + "Autenticacion" + "/CambiarEntorno/" + empCodigo, null)
            .pipe(
                tap((response) => {
                    // let usuario = this.localStorage.obtenerUsuario();
                    this.localStorage.limpiarLocalStorage();
                    //this.localStorage.guardarToken(response);
                    // this.localStorage.guardarUsuario(usuario);
                    this.credentialsService.setCredentials({ codigoModulo: "FIONA", token: response }, true);
                    return of(response)
                })
            )
    }

    public obtenerPermisosVistaAcciones(visCodigo: string) {
        return this.http.get<any>(environment.urlApiAdmin + "usuario" + "/Permisos/" + visCodigo)
            .pipe(
                tap(response => {
                    this.localStorage.guardarPermisos(visCodigo, response);
                    return response;
                })
            )
    }

    /**
     * Logs out the user and clear credentials.
     * @return True if the user was logged out successfully.
     */
    logout(): Observable<boolean> {
        // Customize credentials invalidation here
        this.credentialsService.setCredentials();
        return of(true);
    }

}