import { Injectable } from "@angular/core";
import { Common, FionaResponse } from "@core";
import { Plan } from "@app/@core/models/plan";
import { environment } from "@env/environment";
import { HttpClient } from "@angular/common/http";

export interface PlanRequest
{
    id?: number;
    aplicaCupon: boolean;
    codigo: string;
}

@Injectable({
    providedIn: "root"
})
export class PlanService {
    private readonly ENDPOINT: string = "plan";

    constructor(
        private http: HttpClient,
        private common: Common,
    ) { }

    public getColumnDefsAgg(_cellRendererParams: any) {
        return [
            {
                field: "id",
                headerName: "",
                cellRenderer: "settingsCellRendererComponent",
                cellRendererParams: _cellRendererParams,
            },
            {
                field: "aplicaCupon",
                headerName: "Cupón",
                cellRenderer: "cuponRendererComponent",
            },
            {
                field: "codigo",
                headerName: "Código",
            },
            {
                sort: "desc",
                field: "date",
                headerName: "Fecha Modificación",
            },
            {
                field: "user",
                headerName: "Usuario",
            },
        ]
    }

    public all() {
        return this.http.get<FionaResponse<Array<Plan>>>(`${environment.urlApiFiona}${this.ENDPOINT}`);
    }

    public add(request: PlanRequest) {
        return this.http.post<FionaResponse<Array<string>>>(`${environment.urlApiFiona}${this.ENDPOINT}`, request);
    }

    public update(request: PlanRequest) {
        return this.http.put<FionaResponse<Array<string>>>(`${environment.urlApiFiona}${this.ENDPOINT}/${request.id}`, request);
    }
}