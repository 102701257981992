import { Component } from '@angular/core';

// Dictionaries for German and Russian languages
import * as esMessages from "devextreme/localization/messages/es.json";
import { locale, loadMessages } from "devextreme/localization";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})

export class AppComponent {
  title = '';
  constructor() {
    loadMessages(esMessages);
    locale("es");
  }
}
