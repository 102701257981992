import { Observable } from "rxjs";
import { FionaResponse } from "@core";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { HttpClient } from "@angular/common/http";
import { Intermediary, IntermediaryAudit } from "@core/models";

@Injectable({
    providedIn: "root"
})
export class IntermediaryService {
    private readonly ENDPOINT: string = "intermediador";

    constructor(
        private http: HttpClient,
    ) { }

    public getColumnDefsAgg(_cellRendererParams: any) {
        return [
            {
                field: "",
                headerName: "",
                cellRenderer: "settingsCellRendererComponent",
                cellRendererParams: _cellRendererParams,
            },
            {
                field: "urlToken",
                headerName: "Url token",
            },
            {
                field: "urlEntrada",
                headerName: "Url Entrada",
            },
            {
                field: "urlSalida",
                headerName: "Url Salida",
            },
            {
                field: "clienteId",
                headerName: "Cliente",
            },
            {
                field: "tipoPermiso",
                headerName: "Tipo Permiso",
            },
            {
                field: "persona.fullName",
                headerName: "Persona",
            },
            {
                field: "usuario",
                headerName: "Usuario",
            },
            {
                field: "clave",
                headerName: "Clave",
            },
            {
                field: "token",
                headerName: "Token",
            },
            {
                sort: "desc",
                field: "date",
                headerName: "Fecha Modificación",
            },
            {
                field: "user",
                headerName: "Usuario",
            },
        ]
    }

    public all(): Observable<FionaResponse<Array<IntermediaryAudit>>> {
        return this.http.get<FionaResponse<Array<IntermediaryAudit>>>(`${environment.urlApiFiona}${this.ENDPOINT}`);
    }

    public add(request: Intermediary): Observable<FionaResponse<Array<string>>> {
        return this.http.post<FionaResponse<Array<string>>>(`${environment.urlApiFiona}${this.ENDPOINT}`, request);
    }

    public update(request: Intermediary): Observable<FionaResponse<Array<string>>> {
        return this.http.put<FionaResponse<Array<string>>>(`${environment.urlApiFiona}${this.ENDPOINT}/${request.id}`, request);
    }

    public delete(id: number): Observable<FionaResponse<Array<string>>> {
        return this.http.delete<FionaResponse<Array<string>>>(`${environment.urlApiFiona}${this.ENDPOINT}`);
    }
}