import { Observable } from "rxjs";
import { FionaResponse } from "@core";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { HttpClient } from "@angular/common/http";

@Injectable({
    providedIn: "root"
})
export class PersonService {
    private readonly ENDPOINT: string = "persona";

    constructor(
        private http: HttpClient,
    ) { }

    public all(): Observable<FionaResponse<Array<any>>> {
        return this.http.get<FionaResponse<Array<any>>>(`${environment.urlApiFiona}${this.ENDPOINT}`);
    }
}