import { Injectable } from "@angular/core";

@Injectable()
export class Common {

    static defaultColDef: any = { resizable: 1, sortable: true, minWidth: 100 };
    
    static excelStyles: any = [
        {
            id: 'dateISO',
            dataType: 'DateTime',
            numberFormat: {
                format: "dd/mm/yyy h:mm:ss",
            },
        },
        {
            id: 'currencyFormat',
            numberFormat: {
                format: '$ #,##0',
            },
        },
    ];

    static localeTextAggrid: any = {
        // Start of ag-Grid locale translations
        selectAll: "(Seleccionar todo)",
        searchOoo: "Buscar...",
        blanks: "(Espacios en blanco)",
        noMatches: "No hay coincidencias",

        // Number Filter & Text Filter
        filterOoo: "Filtrar...",
        equals: "Igual",
        notEqual: "No es igual",
        empty: "Elige uno",

        // Number Filter
        lessThan: "Menos que",
        greaterThan: "Mas grande que",

        // Text Filter
        contains: "Contiene",

        // Date Filter
        dateFormatOoo: "yyyy-mm-dd",

        // Filter Buttons
        applyFilter: "Aplicar",
        resetFilter: "Reiniciar",

        // Filter Titles
        textFilter: "Filtro de texto",
        numberFilter: "Filtro de número",
        dateFilter: "Filtro de fecha",
        setFilter: "Filtro de valores",

        // Side Bar
        columns: "Columnas",
        filters: "Filtros",

        // Other
        loadingOoo: "Cargando...",
        noRowsToShow: "No hay filas para mostrar",
        enabled: "Habilitado",

        // Menu
        pinColumn: "Pin columna",
        pinLeft: "Pin a la izquierda",
        pinRight: "Pin a la derecha",
        noPin: "No Pin",

        autosizeThiscolumn: "Tamaño automático de esta columna",
        autosizeAllColumns: "Tamaño automático de todas las columnas",
        resetColumns: "Restablecer columnas",
        copy: "Copiar",
        ctrlC: "Ctrl+C",
        copyWithHeaders: "Copiar con encabezados",
        paste: "Pegar",
        ctrlV: "Ctrl+V",
        export: "Exportar",
        csvExport: "CSV exportar",
        excelExport: "Excel exportar (.xlsx)",
        excelXmlExport: "Excel exportar (.xml)"
    };

    /**
     *
     */
    constructor() {
    }

    public fancyTimeFormatter(value: number): string {
        var day = ~~(value / 1440);
        var hrs = ~~((value % 1440) / 60);
        var mins = ~~(value % 60);

        var ret = `${day}d ${hrs}h ${mins}m`;
        return ret;
    }

    public dateFormatter(params: any): string {
        let result: string = "";
        if (params.value)
            result = new Date(params.value).toLocaleString("pt");
        return result;
    }

    public currencyFormatter(params: any): string {
        let num: string = "0";
        if (params.value)
            num = Math.round(params.value).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
        return `$${num}`;
    }

    public numberFormatter(params: any): string {
        let num: string = "0";
        if (params.value)
            num = Math.round(params.value).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
        return `${num}`;
    }

    public b64toBlob(b64Data: string, contentType: string): Blob {
        contentType = contentType || '';
        var sliceSize = 512;
        b64Data = b64Data.replace(/^[^,]+,/, '');
        b64Data = b64Data.replace(/\s/g, '');
        var byteCharacters = window.atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);

            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        var blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }


    public formatDate(date: Date): string {
        let d = new Date(date),
            month = (d.getMonth() + 1).toString().padStart(2, "0"),
            day = d.getDate().toString().padStart(2, "0"),
            year = d.getFullYear();

        return [year, month, day].join('-');
    }

    public formatTime(date: Date): string {
        let d = new Date(date),
            hour = d.getHours().toString().padStart(2, "0"),
            minute = d.getMinutes().toString().padStart(2, "0"),
            second = d.getSeconds().toString().padStart(2, "0");

        return [hour, minute, second].join(':');
    }

    public formatISO(date: Date): string  {
        return `${this.formatDate(date)}T${this.formatTime(date)}.000`;
    }
}