import { Crypto } from "./crypto"
import { Injectable } from "@angular/core";
import { Credentials } from "@core/services";

@Injectable()
export class LocalStorage {

    crypto: Crypto;

    readonly MENUS = "m";
    readonly VISTAS = "v";
    readonly PERMISOS = "p";
    readonly MODULO = "mod";
    readonly EMPRESA = "emp";
    readonly USUARIO = "u";

    readonly DUPLICADO = "d";
    
    readonly RUTAREPORTES = "rr";
    readonly RUTAREPORTESHISTORICOS = "rrh";
    
    readonly ZONAHORARIA = "zn";
    
    readonly ROLESUSUARIO = "ru"
    readonly INFOUSUARIO = "iu";

    readonly COLUMNSTATE = "cs";
    readonly CATEGORIAS = "ct";
    readonly REGLASTRAN = "rt";
    readonly TIPOCLIENTE = "tc";
    readonly FORMASPAGO = "fm";
    readonly RUTAREPORTESREPO = "rrr";

    constructor() {
        this.crypto = new Crypto();
    }

    public guardarToken(cred: Credentials): boolean {
        let _cred: Credentials = { codigoModulo: cred.codigoModulo, token: ""};
        let token = cred.token;
        if (!cred) return false;
        if (!token) return false;

        let tokenLength = token.length;
        let mediumIndex = Math.floor(tokenLength / 2);
        let tokenA = token.substring(0, mediumIndex);
        let tokenB = token.substring(mediumIndex);
        _cred.token = this.stringReverse(tokenB);
        localStorage.setItem("xp", this.crypto.encriptar(JSON.stringify(_cred)));
        _cred.token = this.stringReverse(tokenA);
        localStorage.setItem("fp", this.crypto.encriptar(JSON.stringify(_cred)));
        return true;
    }

    public obtenerToken(): Credentials {
        let a = this.crypto.desencriptar(localStorage.getItem("fp"));
        if (!a) return;
        let cred: Credentials = JSON.parse(a);
        if  (!cred) return;

        let tokenA = this.stringReverse(cred.token);
        
        let b = this.crypto.desencriptar(localStorage.getItem("xp"));

        if (!b) return;
        cred = JSON.parse(b);
        if  (!cred) return;
        
        let tokenB =  this.stringReverse(cred.token);
        
        if (tokenA && tokenB) {
            return  { 
                codigoModulo: cred.codigoModulo,
                token: tokenA.concat(tokenB)
            };
        }
    }

    public eliminarToken() {
        localStorage.removeItem("fp");
        localStorage.removeItem("xp");
    }

    public stringReverse(texto: string): string {
        if (texto) {
            return Array.from(texto).reverse().join("");
        }
    }

    public guardarMenus(menusJson: any) {
        let encry = this.crypto.encriptar(JSON.stringify(menusJson));
        let rever = this.stringReverse(encry);
        localStorage.setItem(this.MENUS, rever);
    }

    public obtenerMenus(): any {
        let rever = localStorage.getItem(this.MENUS);
        let encry = this.stringReverse(rever);
        let menusJson = this.crypto.desencriptar(encry);
        if (menusJson) {
            return JSON.parse(menusJson);
        }
    }

    public guardarVistas(vistas: any) {
        let encry = this.crypto.encriptar(JSON.stringify(vistas));
        let rever = this.stringReverse(encry);
        localStorage.setItem(this.VISTAS, rever);
    }

    public obtenerVistas(): any {
        let rever = localStorage.getItem(this.VISTAS);
        let encry = this.stringReverse(rever);
        let vistas = this.crypto.desencriptar(encry);
        if (vistas) {
            return JSON.parse(vistas);
        }
    }

    public guardarPermisos(codigoVista: string, objetoPermisos: any) {
        let encry = this.crypto.encriptar(JSON.stringify(objetoPermisos));
        let rever = this.stringReverse(encry);
        localStorage.setItem(this.PERMISOS + codigoVista, rever);
    }

    public obtenerPermisos(codigoVista: string) {
        let local = localStorage.getItem(this.PERMISOS + codigoVista);
        let texto = this.stringReverse(local);
        let menusJson = this.crypto.desencriptar(texto);
        if (menusJson) {
            return JSON.parse(menusJson);
        }
    }

    public guardarMisModulos(modulos: Array<string>) {
        let encry = this.crypto.encriptar(modulos.join(","));
        let rever = this.stringReverse(encry);
        localStorage.setItem(this.MODULO, rever);
    }

    public obtenerMisModulos(): Array<string> {
        let rever = localStorage.getItem(this.MODULO);
        let encry = this.stringReverse(rever);
        let modulos = this.crypto.desencriptar(encry);
        if (modulos) {
            return modulos.split(",");
        }
    }

    public guardarEmpresaActual(empresa: any) {
        let encry = this.crypto.encriptar(JSON.stringify(empresa));
        let rever = this.stringReverse(encry);
        localStorage.setItem(this.EMPRESA, rever);
    }

    public obtenerEmpresaActual(): any {
        let rever = localStorage.getItem(this.EMPRESA);
        let encry = this.stringReverse(rever);
        var empresaJson = this.crypto.desencriptar(encry);
        if (empresaJson) {
            return JSON.parse(empresaJson);
        }
    }

    public guardarUsuario(codigoUsuario: string) {
        localStorage.setItem(this.USUARIO, codigoUsuario);
    }

    public obtenerUsuario(): string {
        return localStorage.getItem(this.USUARIO);
    }

    public guardarRutaBaseReportes(ruta: string) {
        let jsonText = JSON.stringify(ruta);
        let encry = this.crypto.encriptar(jsonText);
        localStorage.setItem(this.RUTAREPORTES, encry);
    }

    public obtenerRutaBaseReportes(): string {
        let encry = localStorage.getItem(this.RUTAREPORTES);
        let jsonText = this.crypto.desencriptar(encry);
        if (jsonText) {
            return JSON.parse(jsonText);
        }
    }

    public guardarRutaBaseReportesHistoricos(ruta: string) {
        let jsonText = JSON.stringify(ruta);
        let encry = this.crypto.encriptar(jsonText);
        localStorage.setItem(this.RUTAREPORTESHISTORICOS, encry);
    }

    public obtenerRutaBaseReportesHistoricos(): string {
        let encry = localStorage.getItem(this.RUTAREPORTESHISTORICOS);
        let jsonText = this.crypto.desencriptar(encry);
        if (jsonText) {
            return JSON.parse(jsonText);
        }
    }

    public guardarRolesUsuario(roles: any) {
        let jsonText = JSON.stringify(roles);
        let encry = this.crypto.encriptar(jsonText);
        localStorage.setItem(this.ROLESUSUARIO, encry);
    }

    public obtenerRolesUsuario(): any {
        let encry = localStorage.getItem(this.ROLESUSUARIO);
        let jsonText = this.crypto.desencriptar(encry);
        if (jsonText) {
            return JSON.parse(jsonText);
        }
    }

    public guardarParametro(ruta: string, nombreParametro: string) {
        let jsonText = JSON.stringify(ruta);
        let encry = this.crypto.encriptar(jsonText);
        localStorage.setItem(nombreParametro, encry);
    }

    // public guardarParametroDetalle(ruta: ParametroDetalleModel, nombreParametro: string) {
    //     let jsonText = JSON.stringify(ruta);
    //     let encry = this.crypto.encriptar(jsonText);
    //     localStorage.setItem(nombreParametro, encry);
    // }

    // public obtenerParametro(nombreParametro: string): ParametroDetalleModel {
    //     let encry = localStorage.getItem(nombreParametro);
    //     let jsonText = this.crypto.desencriptar(encry);
    //     if (jsonText) {
    //         return JSON.parse(jsonText);
    //     }
    // }

    public guardarDuplicado(codigoVista: string, objeto: any) {
        let jsonText = JSON.stringify(objeto);
        let encry = this.crypto.encriptar(jsonText);
        localStorage.setItem(this.DUPLICADO + codigoVista, encry);
    }

    public obtenerDuplicado(codigoVista: string): any {
        let encry = localStorage.getItem(this.DUPLICADO + codigoVista);
        let jsonText = this.crypto.desencriptar(encry);
        if (jsonText) {
            return JSON.parse(jsonText);
        }
    }

    public eliminarDuplicado(codigoVista: string) {
        localStorage.removeItem(this.DUPLICADO + codigoVista);
    }

    public limpiarLocalStorage() {
        localStorage.clear();
    }

    public removeItem(item: string) {
        localStorage.removeItem(item);
    }

    public guardarZonaHoraria(zonaHoraria: number) {
        let jsonText = JSON.stringify(zonaHoraria);
        let encry = this.crypto.encriptar(jsonText);
        localStorage.setItem(this.ZONAHORARIA, encry);
    }

    public obtenerZonaHoraria(): number {
        let encry = localStorage.getItem(this.ZONAHORARIA);
        let jsonText = this.crypto.desencriptar(encry);
        if (jsonText) {
            return JSON.parse(jsonText);
        }
    }

    public guardarInfoUsuario(usuario: any) {
        let encry = this.crypto.encriptar(JSON.stringify(usuario));
        let rever = this.stringReverse(encry);
        localStorage.setItem(this.INFOUSUARIO, rever);
    }

    public obtenerInfoUsuario(): any {
        let rever = localStorage.getItem(this.INFOUSUARIO);
        let encry = this.stringReverse(rever);
        var usuarioJson = this.crypto.desencriptar(encry);
        if (usuarioJson) {
            return JSON.parse(usuarioJson);
        }
    }
    
    public guardarColumnasGrid (columnApi : any) {
        //let columnsState
        //var columnGroupState = JSON.stringify(columnApi.getColumnGroupState(), (key, value ) => {
        //    console.log(" guardaEstadoGrid lambda ")
        //    if (typeof value === "function") {
        //        return "/Function(" + value.toString() + ")/";
        //    }
        //    return value;
        //});
        let columnsState = columnApi.getColumnState();
        localStorage.setItem(this.COLUMNSTATE, JSON.stringify(columnsState));
    }

    public obtenerColumnasGrid (){
        let json = localStorage.getItem (this.COLUMNSTATE);

        try{
            let obj =JSON.parse(json);
            //obj = null;
            return  obj ;
        }
        catch(e)
        {
            console.error(e);
            return null;
        }
    }

    public guardarCategorias(categorias: any) {
        let encry = this.crypto.encriptar(JSON.stringify(categorias));
        let rever = this.stringReverse(encry);
        localStorage.setItem(this.CATEGORIAS, rever);
    }

    public obtenerCategorias(): any {
        let rever = localStorage.getItem(this.CATEGORIAS);
        let encry = this.stringReverse(rever);
        var resultJson = this.crypto.desencriptar(encry);
        if (resultJson) {
            return JSON.parse(resultJson);
        }
    }

    public guardarTipoCliente(tipoClientes: any) {
        let encry = this.crypto.encriptar(JSON.stringify(tipoClientes));
        let rever = this.stringReverse(encry);
        localStorage.setItem(this.TIPOCLIENTE, rever);
    }

    public obtenerTipoCliente(): any {
        let rever = localStorage.getItem(this.TIPOCLIENTE);
        let encry = this.stringReverse(rever);
        var resultJson = this.crypto.desencriptar(encry);
        if (resultJson) {
            return JSON.parse(resultJson);
        }
    }

    public guardarFormasPago(formaPagos: any) {
        let encry = this.crypto.encriptar(JSON.stringify(formaPagos));
        let rever = this.stringReverse(encry);
        localStorage.setItem(this.FORMASPAGO, rever);
    }

    public obtenerFormasPago(): any {
        let rever = localStorage.getItem(this.FORMASPAGO);
        let encry = this.stringReverse(rever);
        var resultJson = this.crypto.desencriptar(encry);
        if (resultJson) {
            return JSON.parse(resultJson);
        }
    }

    public guardarRutaBaseReportesRepo(ruta: string) {
        let jsonText = JSON.stringify(ruta);
        let encry = this.crypto.encriptar(jsonText);
        localStorage.setItem(this.RUTAREPORTESREPO, encry);
    }

    public obtenerRutaBaseReportesRepo(): string {
        let encry = localStorage.getItem(this.RUTAREPORTESREPO);
        let jsonText = this.crypto.desencriptar(encry);
        if (jsonText) {
            return JSON.parse(jsonText);
        }
    }
}