export enum MenusEnum {
    plan = "Plan",
    home = "Home",
    vehicle = "Vehicle",
    tickets = "Tickets",
    parking = "Parking",
    parameter = "Parameter",
    pointAttent = "PointAttent",
    intermediary = "Intermediary",
    searchVehicle = "SearchVehicle",
    inventoryVehicle = "InventoryVehicle",
}