import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { ViewsEnum } from "@core/constants/views";
import { LocalStorage } from "@core/common/localStorage";
import { CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from "@angular/router";

@Injectable({
    providedIn: "root"
})
export class ActionGuard implements CanActivateChild {

    constructor(
        private localStore: LocalStorage,
    ) {
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        let result = false;
        const codigoVista = route.data.view;
        if (codigoVista != ViewsEnum.home) {
            if (codigoVista) {
                let vistas: Array<string>;
                vistas = this.localStore.obtenerVistas();
                if (vistas.includes(codigoVista)) {
                    result = true;
                }
                else
                    console.info("CanActivateVista: No tiene permisos sobre la vista " + codigoVista);
            } 
            else 
                console.error("CanActivateVista: No se configuró el código de la vista a validar");
        } 
        else
            result = true;

        return result;
    }

}