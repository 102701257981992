import { Observable } from "rxjs";
import { FionaResponse } from "@core";
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { VehicleAudit, VehicleCrud } from '@core/models';

@Injectable({
    providedIn: 'root'
})
export class VehicleService {
    private readonly ENDPOINT: string = "vehiculo";

    constructor(
        private http: HttpClient) { }

    public getColumnDefsAgg(_cellRendererParams: any) {
        return [
            {
                field: "id",
                headerName: "",
                checkboxSelection: true,
                cellRenderer: "settingsCellRendererComponent",
                cellRendererParams: _cellRendererParams,
            },
            {
                field: "placa",
                headerName: "Placa",
            },
            {
                field: "descripcion",
                headerName: "Descripción",
            },
            {
                sort: "desc",
                field: "date",
                headerName: "Fecha Modificación",
            },
            {
                field: "estadoVehiculo",
                headerName: "Estado",
            },
            {
                field: "estadoPlanVehiculo",
                headerName: "Estado Plan",
                cellRenderer: "badgeComponent",
            },
            {
                field: "plan",
                headerName: "Plan",
            },
            {
                field: "user",
                headerName: "Usuario",
            }
        ];
    }

    public all(): Observable<FionaResponse<Array<VehicleAudit>>> {
        return this.http.get<FionaResponse<Array<VehicleAudit>>>(`${environment.urlApiFiona}${this.ENDPOINT}/planes`);
    }

    public add(formData: VehicleCrud): Observable<FionaResponse<string>> {
        return this.http.post<FionaResponse<string>>(`${environment.urlApiFiona}${this.ENDPOINT}`, formData);
    }

    public update(formData: VehicleCrud): Observable<FionaResponse<string>> {
        return this.http.put<FionaResponse<string>>(`${environment.urlApiFiona}${this.ENDPOINT}/${formData.id}`, formData);
    }

    public get(plate: string): Observable<FionaResponse<any>> {
        return this.http.get<FionaResponse<any>>(`${environment.urlApiFiona}${this.ENDPOINT}/${plate}`)
    }
}
