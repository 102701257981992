import * as CryptoJS from 'crypto-js';

export class Crypto {

    key = '9raG9I8Zt2oQ8jWO';
    key2 = 'cdyLnsGiTW08wQC';

    public encriptar(texto: any): string {
        if (texto) {
            return CryptoJS.AES.encrypt(texto, this.key).toString();
        }
    };

    public desencriptar(texto: string): string {
        if (texto) {
            return CryptoJS.AES.decrypt(texto, this.key).toString(CryptoJS.enc.Utf8);
        }
    }

    public encriptarMensaje(texto: any): string {
        if (texto) {
            return CryptoJS.AES.encrypt(texto, this.key2).toString();
        }
    };

    public desencriptarMensaje(texto: string): string {
        if (texto) {
            return CryptoJS.AES.decrypt(texto, this.key2).toString(CryptoJS.enc.Utf8);
        }
    }

    public encriptarInfo(text: string): string {
        if (text) {
            let totalIndex = text.length;
            let mediumIndex = Math.floor(totalIndex / 2);
            let tokenA = this.stringReverse(text.substring(0, mediumIndex));
            let tokenB = this.stringReverse(text.substring(mediumIndex));
            return tokenB.concat(tokenA);
        }
    }

    public desencriptarInfo(text: string): string {
        if (text) {
            let totalIndex = text.length;
            let mediumIndex = Math.floor(totalIndex / 2);
            let tokenA = this.stringReverse(text.substring(0, mediumIndex));
            let tokenB = this.stringReverse(text.substring(mediumIndex));
            return tokenB.concat(tokenA);
        }
    }

    public stringReverse(texto: string): string {
        if (texto) {
            return Array.from(texto).reverse().join('');
        }
    }

}