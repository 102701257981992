import { FionaResponse } from "@core";
import { Observable, of } from "rxjs";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { HttpClient } from "@angular/common/http";

export interface DataFilter {
    fechaInicio: string;
    fechaFin: string;
    intermediadorId: number;
}

/**
 * Provides a base for authentication workflow.
 * The login/logout methods should be replaced with proper implementation.
 * 
 * TODO: Dejar carpeta de servicios tranversales a toda la app.
 */
@Injectable({
    providedIn: "root"
})
export class ReportService {
    private readonly ENDPOINT: string = "reporte";

    constructor(
        private http: HttpClient
    ) { }

    public consolidated(data: DataFilter): Observable<FionaResponse<string>> {
        return this.http.post<FionaResponse<string>>(`${environment.urlApiFiona}/recaudo/consolidado`, data);
    }

    public detailed(data: DataFilter): Observable<FionaResponse<string>> {
        return this.http.post<FionaResponse<string>>(`${environment.urlApiFiona}/recaudo/detallado`, data);
    }

    public inventoryVehicle(): Observable<FionaResponse<string>> {
        return this.http.get<FionaResponse<string>>(`${environment.urlApiFiona}${this.ENDPOINT}/inventarioVehiculos`);
    }
}