export enum ViewsEnum {
    plan = "Plan",
    home = "Home",
    vehicle = "Vehicle",
    tickets = "Tickets",
    parameter = "Parameter",
    pointAttent = "PointAttent",
    intermediary = "Intermediary",
    searchVehicle = "SearchVehicle",
    inventoryVehicle = "InventoryVehicle",
}