import { NgModule } from "@angular/core";
import { TokenInterceptor } from "@core";
import { LocalStorage, Common } from "@core";
import { CommonModule } from "@angular/common";
import { AppComponent}  from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { BrowserModule } from "@angular/platform-browser";
import { LoadingBarRouterModule } from "@ngx-loading-bar/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { rootReducer, ArchitectUIState } from "./theme-options/store";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgRedux, NgReduxModule, DevToolsExtension } from "@angular-redux/store";

// BOOTSTRAP COMPONENTS
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { PERFECT_SCROLLBAR_CONFIG } from "ngx-perfect-scrollbar";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { ChartsModule } from "ng2-charts";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

// DEMO PAGES

// // Dashboards

// import {AnalyticsComponent} from "./DemoPages/Dashboards/analytics/analytics.component";

// // Pages

// import {ForgotPasswordBoxedComponent} from "./DemoPages/UserPages/forgot-password-boxed/forgot-password-boxed.component";
// import {LoginBoxedComponent} from "./DemoPages/UserPages/login-boxed/login-boxed.component";
// import {RegisterBoxedComponent} from "./DemoPages/UserPages/register-boxed/register-boxed.component";

// // Elements

// import {StandardComponent} from "./DemoPages/Elements/Buttons/standard/standard.component";
// import {DropdownsComponent} from "./DemoPages/Elements/dropdowns/dropdowns.component";
// import {CardsComponent} from "./DemoPages/Elements/cards/cards.component";
// import {ListGroupsComponent} from "./DemoPages/Elements/list-groups/list-groups.component";
// import {TimelineComponent} from "./DemoPages/Elements/timeline/timeline.component";
// import {IconsComponent} from "./DemoPages/Elements/icons/icons.component";

// // Components

// import {AccordionsComponent} from "./DemoPages/Components/accordions/accordions.component";
// import {TabsComponent} from "./DemoPages/Components/tabs/tabs.component";
// import {CarouselComponent} from "./DemoPages/Components/carousel/carousel.component";
// import {ModalsComponent} from "./DemoPages/Components/modals/modals.component";
// import {ProgressBarComponent} from "./DemoPages/Components/progress-bar/progress-bar.component";
// import {PaginationComponent} from "./DemoPages/Components/pagination/pagination.component";
// import {TooltipsPopoversComponent} from "./DemoPages/Components/tooltips-popovers/tooltips-popovers.component";

// // Tables

// import {RegularComponent} from "./DemoPages/Tables/regular/regular.component";
// import {TablesMainComponent} from "./DemoPages/Tables/tables-main/tables-main.component";

// // Widgets

// import {ChartBoxes3Component} from "./DemoPages/Widgets/chart-boxes3/chart-boxes3.component";

// // Forms Elements

// import {ControlsComponent} from "./DemoPages/Forms/Elements/controls/controls.component";
// import {LayoutComponent} from "./DemoPages/Forms/Elements/layout/layout.component";

// // Charts

// import {ChartjsComponent} from "./DemoPages/Charts/chartjs/chartjs.component";

// // Chart.js Examples

// import {LineChartComponent} from "./DemoPages/Charts/chartjs/examples/line-chart/line-chart.component";
// import {BarChartComponent} from "./DemoPages/Charts/chartjs/examples/bar-chart/bar-chart.component";
// import {ScatterChartComponent} from "./DemoPages/Charts/chartjs/examples/scatter-chart/scatter-chart.component";
// import {RadarChartComponent} from "./DemoPages/Charts/chartjs/examples/radar-chart/radar-chart.component";
// import {PolarAreaChartComponent} from "./DemoPages/Charts/chartjs/examples/polar-area-chart/polar-area-chart.component";
// import {BubbleChartComponent} from "./DemoPages/Charts/chartjs/examples/bubble-chart/bubble-chart.component";
// import {DynamicChartComponent} from "./DemoPages/Charts/chartjs/examples/dynamic-chart/dynamic-chart.component";
// import {DoughnutChartComponent} from "./DemoPages/Charts/chartjs/examples/doughnut-chart/doughnut-chart.component";
// import {PieChartComponent} from "./DemoPages/Charts/chartjs/examples/pie-chart/pie-chart.component";
//Dev Extreme
import { DxDataGridModule, DxCalendarModule, DxTreeViewModule,DxSelectBoxModule,DxTabPanelModule,DxDateBoxModule, 
  DxContextMenuModule, DxDropDownBoxModule, DxPopupModule,DxNumberBoxModule, DxListModule, DxBulletModule, DxTextAreaModule, DxFormModule, DxFilterBuilderModule, DxButtonModule  } from "devextreme-angular";
import { DxoFontModule, DxoMarginModule, DxoPagerModule, DxoSizeModule, DxoTooltipModule } from "devextreme-angular/ui/nested";


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  declarations: [

    // LAYOUT

    AppComponent,
    // PagesLayoutComponent,
    // PageTitleComponent,

    // // DEMO PAGES

    // // Dashboards

    // AnalyticsComponent,

    // // User Pages

    // ForgotPasswordBoxedComponent,
    // LoginBoxedComponent,
    // RegisterBoxedComponent,

    // // Elements

    // StandardComponent,
    // IconsComponent,
    // DropdownsComponent,
    // CardsComponent,
    // ListGroupsComponent,
    // TimelineComponent,

    // // Components

    // AccordionsComponent,
    // TabsComponent,
    // CarouselComponent,
    // ModalsComponent,
    // ProgressBarComponent,
    // PaginationComponent,
    // TooltipsPopoversComponent,

    // // Tables

    // RegularComponent,
    // TablesMainComponent,

    // // Dashboard Boxes

    // ChartBoxes3Component,

    // // Form Elements

    // ControlsComponent,
    // LayoutComponent,

    // // CHARTS

    // ChartjsComponent,

    // // Chart.js Examples

    // LineChartComponent,
    // BarChartComponent,
    // DoughnutChartComponent,
    // RadarChartComponent,
    // PieChartComponent,
    // PolarAreaChartComponent,
    // DynamicChartComponent,
    // BubbleChartComponent,
    // ScatterChartComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgReduxModule,
    CommonModule,
    LoadingBarRouterModule,
    
    // Angular Bootstrap Components
    
    PerfectScrollbarModule,
    NgbModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    
    // Charts

    ChartsModule,

    //Dev Extreme
    DxListModule,
    
    DxDataGridModule,
    DxCalendarModule,
    DxTreeViewModule,
    DxSelectBoxModule,
    DxTabPanelModule,
    DxDateBoxModule,
    DxContextMenuModule ,
    DxPopupModule,
    DxNumberBoxModule,
    DxDropDownBoxModule,
    DxBulletModule,
    DxoPagerModule,
    DxoMarginModule,
    DxoFontModule,
    DxoTooltipModule,
    DxoSizeModule,
    DxTextAreaModule,
    DxFormModule,
    DxFilterBuilderModule,
    DxButtonModule,

    AppRoutingModule,
  ],
  providers: [
    {
      provide:
      PERFECT_SCROLLBAR_CONFIG,
      // DROPZONE_CONFIG,
      useValue:
      DEFAULT_PERFECT_SCROLLBAR_CONFIG,
      // DEFAULT_DROPZONE_CONFIG,
    },
    {
      provide: 
      HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    LocalStorage,
    Common,
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(private ngRedux: NgRedux<ArchitectUIState>,
              private devTool: DevToolsExtension) {

    this.ngRedux.configureStore(
      rootReducer,
      {} as ArchitectUIState,
      [],
      [devTool.isEnabled() ? devTool.enhancer() : f => f]
    );

  }
}
