import { Observable } from "rxjs";
import { FionaResponse } from "@core";
import { Injectable } from "@angular/core";
import { PointAttent } from "@app/@core/models";
import { environment } from "@env/environment";
import { HttpClient } from "@angular/common/http";

@Injectable({
    providedIn: "root"
})
export class PointAttentService {
    private readonly ENDPOINT: string = "puntoAtencion";

    constructor(
        private http: HttpClient,
    ) { }

    public getColumnDefsAgg(_cellRendererParams: any) {
        return [
            {
                field: "",
                headerName: "",
                cellRenderer: "settingsCellRendererComponent",
                cellRendererParams: _cellRendererParams,
            },
            {
                field: "puntoAtencion",
                headerName: "Punto Atención",
            },
            {
                field: "estado",
                headerName: "Estado",
            },
            {
                field: "estacion",
                headerName: "Estación",
            },
            {
                sort: "desc",
                field: "date",
                headerName: "Fecha Modificación",
            },
            {
                field: "user",
                headerName: "Usuario",
            },
        ]
    }

    public all(): Observable<FionaResponse<Array<PointAttent>>> {
        return this.http.get<FionaResponse<Array<PointAttent>>>(`${environment.urlApiFiona}${this.ENDPOINT}`);
    }

    public add(request: PointAttent): Observable<FionaResponse<Array<string>>> {
        return this.http.post<FionaResponse<Array<string>>>(`${environment.urlApiFiona}${this.ENDPOINT}`, request);
    }

    public update(request: PointAttent): Observable<FionaResponse<Array<string>>> {
        return this.http.put<FionaResponse<Array<string>>>(`${environment.urlApiFiona}${this.ENDPOINT}/${request.id}`, request);
    }

    public delete(id: number): Observable<FionaResponse<Array<string>>> {
        return this.http.delete<FionaResponse<Array<string>>>(`${environment.urlApiFiona}${this.ENDPOINT}`);
    }
}