import { LocalStorage } from "@core";
import { Injectable } from "@angular/core";

export interface Credentials {
  // Customize received credentials here
  codigoModulo: string;
  token: string;
}

const credentialsKey = "credentials";

/**
 * Provides storage for authentication credentials.
 * The Credentials interface should be replaced with proper implementation.
 */
@Injectable({
  providedIn: "root"
})
export class CredentialsService {

  private _credentials: Credentials | null = null;

  constructor(
    private localStorage: LocalStorage,
  ) {
    const savedCredentials =  this.localStorage.obtenerToken();
    if (savedCredentials) {
      this._credentials = savedCredentials;
    }
  }

  /**
   * Checks is the user is authenticated.
   * @return True if the user is authenticated.
   */
  isAuthenticated(): boolean {
    return !!this.credentials;
  }

  /**
   * Gets the user credentials.
   * @return The user credentials or null if the user is not authenticated.
   */
  get credentials(): Credentials | null {
    return this._credentials;
  }

  /**
   * Sets the user credentials.
   * The credentials may be persisted across sessions by setting the `remember` parameter to true.
   * Otherwise, the credentials are only persisted for the current session.
   * @param credentials The user credentials.
   * @param remember True to remember credentials across sessions.
   */
  setCredentials(credentials?: Credentials, remember?: boolean) {
    this._credentials = credentials || null;

    if (credentials) {
      this.localStorage.guardarToken(credentials);
    }
  }

  /**
   * Get token payload deserialize .
   * @return The payload
   */
  get credentialsDeserialize(): any | null {
    let [_, scd] = this.credentials.token.split(".");
    return JSON.parse(atob(scd));
  }

}