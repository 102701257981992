import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { catchError } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { CredentialsService } from "@core/services";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpSentEvent, HttpHeaderResponse, HttpProgressEvent, HttpResponse, HttpUserEvent, HttpErrorResponse } from "@angular/common/http";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(public cred: CredentialsService, public httpClient: HttpClient, public router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    if (this.cred.credentials) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.cred.credentials.token}`
        }
      });
    }
    return <any>next.handle(request).pipe(
      catchError(error => {
        //the app created request for getting new token/session and got 401,meaning the refresh token/session also expired
        if (error.status == 401) {
          this.cred.setCredentials(null);
          this.router.navigate(["/login"]);
        } else if (error.status == 400 || error.status == 500) {
          console.error(error);
        }
        return throwError(error.error);
      })
    );
  }
}